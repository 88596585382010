import * as React from "react"
import Layout from "../components/layout";
import Login from "../components/Login";
import {Reservaciones} from "../components/reservaciones";
import {isLoggedIn} from "../services/auth";

const IndexPage = () => {
    if (isLoggedIn()) {
        return (<Layout>
            <Reservaciones/>
        </Layout>)
    } else {
        return (
            <Layout>
                <Login/>
            </Layout>
        )
    }
}

export default IndexPage
